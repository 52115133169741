import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "video-react/dist/video-react.css";

import React, { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {Helmet} from "react-helmet";

import { API } from 'aws-amplify';

import { Container, Row, Col, Button, Collapse, ToggleButton } from 'react-bootstrap';
import { Player, LoadingSpinner, BigPlayButton, ControlBar, ReplayControl, ForwardControl, PlaybackRateMenuButton, VolumeMenuButton, FullscreenToggle } from 'video-react';


import { IoPlayCircleOutline, IoAttach, IoBarbell, IoChevronDownOutline, IoChevronUpOutline, IoChevronBack, IoChevronForward, 
  IoExpand, IoContract, IoCheckmarkCircleOutline, IoCloseCircleOutline, IoClose, IoReaderOutline } from 'react-icons/io5';

import logoRev from './logo-rev.png';
import loadingGif from './loading.gif';

const DEBUG = false;
const landingPageUrl = "https://www.awslibvn.com/";

function PageMetadata(props) {
  return <Helmet>
    <title>{props.courseName}</title>
  </Helmet>
}

function LoadingContainer() {
  return <Container fluid className='parent-container parent-loading'><img src={loadingGif} alt="loading..." className='loading-gif' /></Container>
}

function SidebarLogo() {
  return <Row xs={12} className='logo-container'>
    <a href={landingPageUrl} className="fill-div">
      <img src={logoRev} alt="Logo AWS" className="logo-aws" />
      <span className='logo-text'>Thư viện Tiếng Việt</span>
    </a>
  </Row>
}

function SideBarLectureInfo(props) {
  return <Row xs={12} className="lecture-info">
    <div className='course-name'>{props.courseName}</div>
    {props.loading ? <div className='lecture-loading'><img src={loadingGif} alt="loading..." className='loading-gif' /></div> : <>
      <div className='chapter-name'>{props.chapterName}</div>
      <div className='lecture-name-h'>{props.lectureName}</div>
    </>}
  </Row>
}

function MenuHeader() {
  return <>
    <div className='table-of-content'>Mục lục</div>
    <div className='menu-divider'/>
  </>
}

function MenuChapterName(props) {
  return <div className='chapter-number'>{props.chapterName}</div>;
}

function MenuSection(props) {
  return <>
    <div className='section-header'>{props.lectureName}</div>
    <div className='menu-divider'/>
  </>
}

function MenuVideo(props) {
  return <>
    <div className={props.className}>
      <a href="#" onClick={props.onClick} className="fill-div">
        <div className='lecture-logo'><IoPlayCircleOutline /></div>
        <div className='lecture-name'>{props.lectureName}</div>
      </a>
    </div>
    <div className='menu-divider'/>
  </>
}

function MenuLab(props) {
  return <>
    <div className={props.className}>
      <a href="#" onClick={props.onClick} className="fill-div">
        <div className='lecture-logo'><IoBarbell /></div>
        <div className='lecture-name'>{props.lectureName}</div>
      </a>
    </div>
    <div className='menu-divider'/>
  </>
}

function MenuDocument(props) {
  return <>
    <div className={props.className}>
      <a href="#" onClick={props.onClick} className="fill-div">
        <div className='lecture-logo'><IoAttach /></div>
        <div className='lecture-name'>{props.lectureName}</div>
      </a>
    </div>
    <div className='menu-divider'/>
  </>
}

function MenuSurvey(props) {
  return <>
    <div className={props.className}>
      <a href="#" onClick={props.onClick} className="fill-div">
        <div className='lecture-logo'><IoReaderOutline /></div>
        <div className='lecture-name'>{props.lectureName}</div>
      </a>
    </div>
    <div className='menu-divider'/>
  </>
}

function ContentLoading() {
  return <div className='player-loading'><img src={loadingGif} alt="loading..." className='loading-gif' /></div>;
}

function LectureContent(props) {
  switch (props.lecture.lecture.type) {
    case "video":
      return <VideoContent 
        videoSrc={props.lecture.lecture.content} 
        setTimeLeft={props.setTimeLeft} 
        handleFullScreen={props.handleFullScreen}
        handleVideoEnded={props.handleVideoEnded} />;
    case "lab":
      return <LabContent desc={props.lecture.lecture.desc} url={props.lecture.lecture.content} openLink={props.openLink} />;
    case "document":
      return <DocumentContent desc={props.lecture.lecture.desc} url={props.lecture.lecture.content} openLink={props.openLink} />;
    case "survey":
      return <SurveyContent desc={props.lecture.lecture.desc} url={props.lecture.lecture.content} openLink={props.openLink} name={props.lecture.lecture.name} />;
    default:
      return <></>;
  };
}

class VideoContent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    this.player.actions.toggleFullscreen=()=>{
      this.props.handleFullScreen();
    };
  }

  handleStateChange(state) {
    this.props.setTimeLeft(Math.floor(state.duration - state.currentTime));
    if (state.ended) 
      this.props.handleVideoEnded();
  }

  render() {
    return (
      <Player
        ref={player => {
          this.player = player;
        }}
        className="transparent-player"
        autoPlay
        playsInline
        fluid={false}
        height="100%"
        width="100%"
        src={this.props.videoSrc}
      >
        <LoadingSpinner />
        <BigPlayButton position="center" />
        <ControlBar>
            <ReplayControl seconds={5} order={2.1} />
            <ForwardControl seconds={5} order={2.2} />
            <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.9, 0.75]} order={7} />
            <VolumeMenuButton order={8} />
            <FullscreenToggle disabled />
          </ControlBar>
      </Player>
    );
  }
}

function LabContent(props) {
  return <div className="lab-content-container">
    <div className="lab-content-header">Bài tập thực hành</div>
    <div className="lab-content-desc">{props.desc}</div>
    <div className="lab-content-link">
      <button onClick={() => {props.openLink(props.url)}}>
        {props.url}
      </button>
    </div>
  </div>;
}

function DocumentContent(props) {
  return <div className="lab-content-container">
    <div className="lab-content-header">Tài liệu tham khảo</div>
    <div className="lab-content-desc">{props.desc}</div>
    <div className="lab-content-link">
      <button onClick={() => {props.openLink(props.url)}}>
        {props.url}
      </button>
    </div>
  </div>;
}

function SurveyContent(props) {
  return <div className="lab-content-container">
    <div className="lab-content-header">{props.name}</div>
    <div className="lab-content-desc">{props.desc}</div>
    <div className="lab-content-link">
      <button onClick={() => {props.openLink(props.url)}}>
        {props.url}
      </button>
    </div>
  </div>;
}

function MainContent(props) {
  const handle = useFullScreenHandle();
  const [fullscreen, setFullscreen] = useState(false);
  const [autoNext, setAutoNext] = useState(true);
  const [timeLeft, setTimeLeft] = useState(100);

  return <FullScreen handle={handle}>
    <div className='content-parent'>
      <div className='content-main'>
        {props.loading ? 
          <ContentLoading /> : 
          <LectureContent lecture={props.lecture} 
            openLink={url => {
              setFullscreen(false);
              window.open(url, '_blank').focus();
            }} 
            setTimeLeft={timeLeft => {
              setTimeLeft(timeLeft);
            }}
            handleFullScreen={() => {
              if (fullscreen) handle.exit(); else handle.enter();
              setFullscreen(!fullscreen);
            }}
            handleVideoEnded={() => {
              if (autoNext && !props.isLast) {
                setTimeLeft(100);
                props.nextLecture();
              }
            }}
          />
        }
      </div>
      <div className='content-control'>
        <div className='content-control-left'>
          {props.isFirst ? 
            <button className='content-control-btn content-control-btn-disabled' disabled>
              <IoChevronBack />
            </button> :
            <button className='content-control-btn' onClick={props.prevLecture}>
              <IoChevronBack />
            </button>}
          {props.isLast ? 
            <button className='content-control-btn content-control-btn-disabled' disabled>
              <IoChevronForward />
            </button> :
            <button className='content-control-btn' onClick={props.nextLecture}>
              <IoChevronForward />
            </button>}
          <ToggleButton
            className="auto-next-control"
            id="toggle-check"
            type="checkbox"
            variant="outline-secondary"
            checked={autoNext}
            onChange={(e) => setAutoNext(!autoNext)}
          >
            Tự động chuyển bài 
            {autoNext ? 
              <IoCheckmarkCircleOutline className='auto-next-control-icon'/> : 
              <IoCloseCircleOutline className='auto-next-control-icon'/>}
          </ToggleButton>
        </div>
        <div className='content-control-right'>
          <button className='content-control-btn' onClick={() => {
            if (fullscreen) handle.exit(); else handle.enter();
            setFullscreen(!fullscreen);
          }}>
            {fullscreen ? <IoContract /> : <IoExpand />}
          </button>
        </div>
      </div>
      {autoNext && !props.isLast && timeLeft!==null && timeLeft<=5 ? 
        <div className='next-lecture'>
          <Row>
            <Col xs={3} className="next-lecture-count">
              {timeLeft}
            </Col>
            <Col xs={9}>
              <Row>
                <Col xs={10} className="next-lecture-header">Bài tiếp theo</Col>
                <Col xs={2}>
                  <button className='next-lecture-cancel' onClick={() => {
                    setAutoNext(false);
                  }}>
                    <IoClose/>
                  </button>
                </Col>
              </Row>
              <Row className="next-lecture-name">
                {props.nextLectureName}
              </Row>
            </Col>
          </Row>
        </div> : 
        <></>}
    </div>
  </FullScreen>
}

class App extends React.Component {
  constructor(props) { 
    super(props); 
    this.state = { 
      loading: true,
      course: null,
      lecture: {
        chapterId: 0,
        lectureId: 0,
        lecture: null
      },
      nextLectureName: "",
    };
  }

  getHashParams() {
    let hashArr = window.location.hash.split("/");
    return {
      course: hashArr[2],
      lecture: hashArr[4]
    }
  }

  setHashParams(course, lecture) {
    if (!lecture)
      window.location.hash = "/course/" + course;
    else 
      window.location.hash = "/course/" + course + "/lecture/" + lecture;
  }

  setLocalStorage(course, lecture) {
    if (!!course) localStorage.setItem('AWSLIBVN_COURSE', course);
    if (!!course && !!lecture) localStorage.setItem('AWSLIBVN_LECTURE' + course, lecture);
  }

  getLocalStorage(course) {
    if (!course) course = localStorage.getItem('AWSLIBVN_COURSE');
    return {
      course: course,
      lecture: localStorage.getItem('AWSLIBVN_LECTURE' + course),
    }
  }

  loadLecture(chapterIndex, lectureIndex) {
    let lectureId = this.state.course.chapters[chapterIndex].lectures[lectureIndex].lectureId;

    const apiName = 'awslibvnlearnapi';
    const path = '/lecture/' + lectureId; 
    const init = {
      response: true, 
    };

    this.setState({loading: true});
  
    API
      .get(apiName, path, init)
      .then(response => {
        this.setState({
          lecture: {
            chapterId: chapterIndex,
            lectureId: lectureIndex,
            lecture: response.data,
          },
          loading: false,
          nextLectureName: this.getNextLectureName(chapterIndex, lectureIndex)
        });
        let hashParams = this.getHashParams();
        this.setHashParams(hashParams.course, response.data.id);
        this.setLocalStorage(hashParams.course, response.data.id);
      })
      .catch(error => {
        console.log(error);
      });
  }

  loadCourse() {
    let hashParams = this.getHashParams();

    let course = hashParams.course;
    let lecture = hashParams.lecture;
    if (!course) {
      let localParams = this.getLocalStorage();
      course = localParams.course;
      lecture = localParams.lecture;
    } else if (!lecture) {
      let localParams = this.getLocalStorage(course);
      lecture = localParams.lecture;
    }

    if (!course && !DEBUG) {
      window.location.href = landingPageUrl;
    }

    const apiName = 'awslibvnlearnapi';
    const path = '/course/' + course;
    const init = {
      response: true, 
    };
  
    API
      .get(apiName, path, init)
      .then(response => {
        this.setHashParams(course, lecture);
        this.setLocalStorage(course, lecture);

        let chapters = JSON.parse(response.data.chapters);
        let firstLectureIndex = 0;
        let hashChapterIndex = -1;
        let hashLectureIndex = -1;
        while (chapters[0].lectures[firstLectureIndex].type === "section") firstLectureIndex++;

        for (let i = 0; i < chapters.length; i++) {
          chapters[i].lectures[0].realIndex = 0;
          for (let j = 1; j < chapters[i].lectures.length; j++) {
            if (chapters[i].lectures[j-1].type === "section")
              chapters[i].lectures[j].realIndex = chapters[i].lectures[j-1].realIndex;
            else 
              chapters[i].lectures[j].realIndex = chapters[i].lectures[j-1].realIndex + 1;
          }
        }

        if (!!lecture)
          for (let i = 0; i < chapters.length; i++) {
            for (let j = 0; j < chapters[i].lectures.length; j++) {
              if (chapters[i].lectures[j].lectureId === lecture) {
                hashChapterIndex = i;
                hashLectureIndex = j;
              }
            }
          }

        this.setState({
          course: {
            name: response.data.name,
            chapters: chapters,
            firstLectureIndex: firstLectureIndex,
          },
        });

        if (hashLectureIndex === -1) 
          this.loadLecture(0, firstLectureIndex);
        else 
          this.loadLecture(hashChapterIndex, hashLectureIndex);
      })
      .catch(error => {
        console.log(error);
        if (!DEBUG)
          window.location.href = landingPageUrl;
      });
  }

  prevLecture() {
    let chapterId = this.state.lecture.chapterId;
    let lectureId = this.state.lecture.lectureId;

    lectureId--;
    if (lectureId < 0) {
      chapterId--;
      lectureId = this.state.course.chapters[chapterId].lectures.length - 1;
    }

    while (this.state.course.chapters[chapterId].lectures[lectureId].type === "section") {
      lectureId--;
      if (lectureId < 0) {
        chapterId--;
        lectureId = this.state.course.chapters[chapterId].lectures.length - 1;
      }
    }

    this.loadLecture(chapterId, lectureId);
  }

  nextLecture() {
    let chapterId = this.state.lecture.chapterId;
    let lectureId = this.state.lecture.lectureId;

    lectureId++;
    if (lectureId === this.state.course.chapters[chapterId].lectures.length) {
      chapterId++;
      lectureId = 0;
    }

    while (this.state.course.chapters[chapterId].lectures[lectureId].type === "section") {
      lectureId++;
      if (lectureId === this.state.course.chapters[chapterId].lectures.length) {
        chapterId++;
        lectureId = 0;
      }
    }

    this.loadLecture(chapterId, lectureId);
  }

  getNextLectureName(chapterId, lectureId) {
    lectureId++;
    if (lectureId === this.state.course.chapters[chapterId].lectures.length) {
      chapterId++;
      lectureId = 0;
    }

    while (chapterId < this.state.course.chapters.length && this.state.course.chapters[chapterId].lectures[lectureId].type === "section") {
      lectureId++;
      if (lectureId === this.state.course.chapters[chapterId].lectures.length) {
        chapterId++;
        lectureId = 0;
      }
    }

    return chapterId < this.state.course.chapters.length && lectureId < this.state.course.chapters[chapterId].lectures.length
      ? this.state.course.chapters[chapterId].lectures[lectureId].name
      : "";
  }

  componentDidMount() {
    this.loadCourse();
  }

  render() {
    return !this.state.course ? 
      <LoadingContainer /> : 
      <Container fluid className='parent-container'>

        <PageMetadata courseName={this.state.course.name} />
        
        {/* Sidebar */}
        <div className='side-bar-container'>
          <SidebarLogo />
          <SideBarLectureInfo 
            loading={this.state.loading}
            courseName={this.state.course.name}
            chapterName={"Chương " + (this.state.lecture.chapterId + 1) + "." 
              + (this.state.course.chapters[this.state.lecture.chapterId].lectures[this.state.lecture.lectureId].realIndex + 1)}
            lectureName={this.state.course.chapters[this.state.lecture.chapterId].lectures[this.state.lecture.lectureId].name} />

          <Row xs={12} className="chapters-container">
            <MenuHeader />

            {this.state.course.chapters.map(
              (chapter, chapterIndex) => {
                let closeState = "close-" + chapterIndex;
                
                return <div className='chapter-container'>
                  <div className='chapter-header'>
                    <MenuChapterName chapterName={"Chương " + (parseInt(chapterIndex) + 1)} />
                    <div className='chapter-collapse'>
                      <Button className='btn-collapse'
                        onClick={() => this.setState({
                          [closeState]: !this.state[closeState]
                        })}
                        aria-expanded={!this.state[closeState]}
                      >
                        {!this.state[closeState] ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                      </Button>
                    </div>
                    <div className='chapter-header-text'>{chapter.name}</div>
                  </div>
                  <div className='menu-divider chapter-header-divider'/>
                  <Collapse in={!this.state[closeState]}>
                    <div className='chapter-content'>
                      {chapter.lectures.map((lecture, lectureIndex) => {
                        switch (lecture.type) {
                          case "section":
                            return <MenuSection lectureName={lecture.name} />;
                          case "survey":
                            return <MenuSurvey 
                              className={!!this.state.lecture.lecture && this.state.lecture.lecture.id === lecture.lectureId ? 'lecture-selected' : 'lecture'}
                              onClick={e => {e.preventDefault(); this.loadLecture(chapterIndex, lectureIndex);}}
                              lectureName={lecture.name} />;
                          case "video":
                            return <MenuVideo 
                              className={!!this.state.lecture.lecture && this.state.lecture.lecture.id === lecture.lectureId ? 'lecture-selected' : 'lecture'}
                              onClick={e => {e.preventDefault(); this.loadLecture(chapterIndex, lectureIndex);}}
                              lectureName={lecture.name} />;
                          case "lab":
                            return <MenuLab
                              className={!!this.state.lecture.lecture && this.state.lecture.lecture.id === lecture.lectureId ? 'lecture-selected' : 'lecture'}
                              onClick={e => {e.preventDefault(); this.loadLecture(chapterIndex, lectureIndex);}}
                              lectureName={lecture.name} />;
                          case "document":
                            return <MenuDocument 
                              className={!!this.state.lecture.lecture && this.state.lecture.lecture.id === lecture.lectureId ? 'lecture-selected' : 'lecture'}
                              onClick={e => {e.preventDefault(); this.loadLecture(chapterIndex, lectureIndex);}}
                              lectureName={lecture.name} />;
                          default: 
                            return <></>;
                        }
                      })}
                    </div>
                  </Collapse>
                </div>
              }
            )}
          </Row>
        </div>

        {/* Content */}
        <div className='video-player-container'>
          <MainContent loading={this.state.loading}
                lecture={this.state.lecture} 
                nextLectureName={this.state.nextLectureName}
                isFirst={this.state.lecture.chapterId === 0 && this.state.course.chapters[this.state.lecture.chapterId].lectures[this.state.lecture.lectureId].realIndex === 0}
                isLast={this.state.lecture.chapterId === this.state.course.chapters.length - 1 && this.state.lecture.lectureId === this.state.course.chapters[this.state.lecture.chapterId].lectures.length - 1}
                prevLecture={() => {this.prevLecture()}}
                nextLecture={() => {this.nextLecture()}} />
        </div>
      </Container>;
  }
}

export default App;